import React from 'react';
import s from "./Main/Main.module.css";

const Footer = () => {
    return (
        <div className={s.footer}>
            Duomo Tropea, 2024
        </div>
    );
};

export default Footer;